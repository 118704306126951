import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from "../../components/Images/loaderget.gif"
import VideoPlayer from './VideoPlayer';
import LivestreamPlayer from './LivestreamPlayer';
import Error404 from '../../Error404/Error404';
import { getWishListAction } from '../../Redux/Actions/apiActions';

const Player = ({ isLoading }) => {
    const { sourcetype, slug } = useParams();
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getWishListAction())
    }, [])

    return (
        isLoading
            ? <div className='d-flex justify-content-center align-items-center paddingTop'><img src={Loader} width={50} height={50} /></div>
            : sourcetype == "video"
                ? <VideoPlayer slug={slug} />
                : sourcetype == "live"
                    ? <LivestreamPlayer slug={slug} />
                    : <Error404 />
    )
}
const mapStateToProps = state => ({
    homepage: state.get_allHomePage_Reducer.homepage,
    isLoading: state.get_allHomePage_Reducer.isLoading,
    settings: state.get_setting_Reducer.settings
});

export default connect(mapStateToProps)(Player);