import axios from 'axios'
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getItem, getItemToken } from '../../Utils/localStorageUtils';
import { initializeI18n } from '../../Utils/Translate';
import { toast } from 'react-toastify';
const footerAction = () => async (dispatch) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Footer-index`);
        dispatch({ type: "GET_FOOTER_LINKS", payload: response.data });
    }
    catch (error) {
        dispatch({ type: "GET_FOOTER_FAILED", payload: error });
    };
}

const homeBannerAction = () => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Sliders`, { Login_user_type: 9, Login_device_type: "web", })
        const ListRes = response.data
        dispatch({ type: "GET_HOMEBANNER", payload: [...ListRes.Admin_sliders, ...ListRes.videos_sliders, ...ListRes.Series_sliders, ...ListRes.LiveStream_sliders, ...ListRes.Audios_sliders, ...ListRes.Episode_sliders] });
    }
    catch (error) {
        dispatch({ type: "GET_HOMEBANNER_FAILED", payload: error });
    };
}

const viewAllAction = (data) => async (dispatch) => {
    try {
        dispatch({ type: "GET_VIEWALL", payload: [data] });
    }
    catch (error) {
        dispatch({ type: "GET_VIEWALL_FAILED", payload: error });
    };
}

const settingAction = () => async (dispatch) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Settings`)
        let result = response.data.setting;
        dispatch({ type: "GET_SETTING", payload: result });
    }
    catch (error) {
        dispatch({ type: "GET_SETTING_FAILED", payload: error });
    };
}
const filterAction = () => async (dispatch) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Filter-Index`)
        let result = response.data;
        dispatch({ type: "GET_FILTER", payload: result });
        return result;

    }
    catch (error) {
        dispatch({ type: "GET_FILTER_FAILED", payload: error });
    };
}
const siteThemeAction = () => async (dispatch) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Site-theme`)
        let result = response.data.Site_theme;
        dispatch({ type: "GET_SITETHEME", payload: result });
    }
    catch (error) {
        dispatch({ type: "GET_SITETHEME_FAILED", payload: error });
    };
}
const displayTranslateLanguageAction = () => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Translate-Languages`)
        let result = response.data.Translate_Languages;
        dispatch({ type: "GET_DISPLAYTRANSLATELANGUAGE", payload: result });
    }
    catch (error) {
        dispatch({ type: "GET_DISPLAYTRANSLATELANGUAGE_FAILED", payload: error });
    };
}

const getUserTranslateLanguageAction = (navigation) => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/User-Translate-Language`, {
            Login_user_type: parseInt(getItem('role'), 10) || 9,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || null,
        }, { headers: getItemToken('access_token') || null }
        )
        const languageResponse = response?.data;
        const currentPath = window.location.pathname;
        const pathSegments = currentPath.split('/');
        pathSegments[1] = languageResponse?.Language_Code
        const newPath = pathSegments.join('/');
        navigation(newPath)
        initializeI18n()
        dispatch({ type: "GET_USERTRANSLATELANGUAGEACTION", payload: languageResponse });
        return languageResponse;
    }
    catch (error) {
        dispatch({ type: "GET_USERTRANSLATELANGUAGEACTION_FAILED", payload: error.response?.data });
        throw error;
    };
}


const videoDetailsAction = (userId, userType, deviceType, slug, accessHeader) => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/videos-details`, {
            Login_user_type: userType,
            Login_device_type: deviceType,
            Login_user_id: userId,
            video_slug: slug
        }, { headers: accessHeader })
        const videoResponse = response?.data?.videos_details;
        dispatch({ type: "GET_VIDEODETAILSACTION", payload: videoResponse });
    }
    catch (error) {
        dispatch({ type: "GET_VIDEODETAILSACTION_FAILED", payload: error.response?.data });
    };
}
// const seriesDetailsAction = (userId, userType, deviceType, slug,accessHeader) => async (dispatch) => {
//     try {
//         const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Series-details`, {
//             Login_user_type: userType,
//             Login_device_type: deviceType,
//             Login_user_id: userId,
//             series_slug: slug,
//         }, { headers: accessHeader })
//         const seriesPPVResponse = response?.data;
//         const seriesResponse = response?.data?.series_details;
//         dispatch({ type: "GET_SERIESDETAILSACTION", payload: seriesResponse, 
//             seriesPPVResponse:seriesPPVResponse });
//         return {seriesPPVResponse,seriesResponse};
//     }
//     catch (error) {
//         dispatch({ type: "GET_SERIESDETAILSACTION_FAILED", payload: error.response.data });
//     };
// }

const seriesDetailsAction = (userId, userType, deviceType, slug, accessHeader) => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Series-details`, {
            Login_user_type: userType,
            Login_device_type: deviceType,
            Login_user_id: userId,
            series_slug: slug,
        }, { headers: accessHeader });
        const seriesResponse = response?.data;
        dispatch({ type: "GET_SERIESDETAILSACTION", payload: seriesResponse });

    } catch (error) {
        console.error('Error during API call:', error);
        dispatch({ type: "GET_SERIESDETAILSACTION_FAILED", payload: error.response?.data || error.message });
    }
}


const episodeDetailsAction = (userId, userType, deviceType, series_slug, episode_slug, accessHeader) => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Episode-details`, {
            Login_user_type: userType,
            Login_device_type: deviceType,
            Login_user_id: userId,
            series_slug: series_slug,
            Episode_slug: episode_slug
        }, { headers: accessHeader })
        const episodeResponse = response?.data;
        dispatch({ type: "GET_EPISODEDETAILSACTION", payload: episodeResponse });
        return episodeResponse;

    }
    catch (error) {
        dispatch({ type: "GET_EPISODEDETAILSACTION_FAILED", payload: error });
    };
}
const seasonDetailsAction = (userId, userType, deviceType, series_id, season_id, accessHeader) => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Series-season-details`, {
            Login_user_type: userType,
            Login_device_type: deviceType,
            Login_user_id: userId,
            series_id: series_id,
            season_id: season_id
        }, { headers: accessHeader })
        const seasonResponse = response?.data;
        dispatch({ type: "GET_SEASONDETAILSACTION", payload: seasonResponse });
        return seasonResponse;

    }
    catch (error) {
        dispatch({ type: "GET_SEASONDETAILSACTION_FAILED", payload: error });
    };
}

const liveDetailsAction = (userId, userType, deviceType, slug, accessHeader) => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Livestream-details`, {
            Login_user_type: userType,
            Login_device_type: deviceType,
            Login_user_id: userId,
            livestream_slug: slug,
        }, { headers: accessHeader })
        const liveResponse = response?.data?.Livestream_details;
        const liveResponsePayment_setting = response?.data?.Payment_setting;
        dispatch({ type: "GET_LIVEDETAILSACTION", payload: liveResponse, liveResponsePayment_setting: liveResponsePayment_setting });
    }
    catch (error) {
        dispatch({ type: "GET_LIVEDETAILSACTION_FAILED", payload: error.response.data });
    };
}
// other_audios
const artistsDetailsAction = (userId, userType, deviceType, slug) => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/artists-depends-videos        `, {
            Login_user_type: userType,
            Login_device_type: deviceType,
            Login_user_id: userId,
            artist_slug: slug,
        })
        const liveResponse = response?.data;
        dispatch({ type: "GET_ARTISTS_DETAILS_ACTION", payload: liveResponse });
    }
    catch (error) {
        dispatch({ type: "GET_ARTISTS_DETAILS_ACTION_FAILED", payload: error });
    };
}

const channelDetailsAction = (userId, userType, deviceType, slug) => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/`, {
            Login_user_type: userType,
            Login_device_type: deviceType,
            Login_user_id: userId,
            artist_slug: slug,
        })
        const liveResponse = response?.data?.Livestream_details;
        dispatch({ type: "GET_CHANNEL_DETAILS_ACTION", payload: liveResponse });
    }
    catch (error) {
        dispatch({ type: "GET_CHANNEL_DETAILS_ACTION_FAILED", payload: error });
    };
}
const contentDetailsAction = (userId, userType, deviceType, slug) => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/`, {
            Login_user_type: userType,
            Login_device_type: deviceType,
            Login_user_id: userId,
            artist_slug: slug,
        })
        const liveResponse = response?.data?.Livestream_details;
        dispatch({ type: "GET_CONTENT_DETAILS_ACTION", payload: liveResponse });
    }
    catch (error) {
        dispatch({ type: "GET_CONTENT_DETAILS_ACTION_FAILED", payload: error });
    };
}
const allHomePageAction = (userId, userType, deviceType, accessHeader) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_Baseurl}/Front-End/All-Homepage`,
            {
                Login_user_type: userType,
                Login_device_type: deviceType,
                Login_user_id: userId,
            },
            { headers: accessHeader }
        );
        dispatch({ type: "GET_ALLHOMEPAGE_ACTION", payload: response.data.Home_page });
    } catch (error) {
        dispatch({ type: "GET_ALLHOMEPAGE_FAILED", payload: error });
    }
}
const movieHomePageAction = () => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/movies-home-page`,
            {
                Login_user_type: parseInt(getItem('role'), 10) || 9,
                Login_device_type: "web",
                Login_user_id: parseInt(getItem('user_id'), 10) || null,
            },
            { headers: getItemToken('access_token') || null }
        );
        dispatch({ type: "MOVIE_HOMEPAGE", payload: response.data.Home_page });
    } catch (error) {
        dispatch({ type: "MOVIE_HOMEPAGE_FAILED", payload: error });
    }
}
const showHomePageAction = () => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/tvshows-home-page`,
            {
                Login_user_type: parseInt(getItem('role'), 10) || 9,
                Login_device_type: "web",
                Login_user_id: parseInt(getItem('user_id'), 10) || null,
            },
            { headers: getItemToken('access_token') || null }
        );
        dispatch({ type: "SHOW_HOMEPAGE", payload: response.data.Home_page });
    } catch (error) {
        dispatch({ type: "SHOW_HOMEPAGE_FAILED", payload: error });
    }
}

const allThumbnailAction = (accessHeader) => async (dispatch) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Thumbnail-Status`, { headers: accessHeader, })
        const ListRes = response.data.Thumbnail_Status
        dispatch({ type: "GET_THUMBNAIL_ACTION", payload: ListRes });
    }
    catch (error) {
        dispatch({ type: "GET_THUMBNAIL_ACTION_FAILED", payload: error });
    };
}
const headerIndexAction = () => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Header-index`, {
            Login_user_type: parseInt(getItem('role'), 10) || 9,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || null,
        }, { headers: getItemToken('access_token') || null })
        const ListRes = response.data
        dispatch({ type: "GET_HEADERINDEX_ACTION", payload: ListRes });
    }
    catch (error) {
        dispatch({ type: "GET_HEADERINDEX_ACTION_FAILED", payload: error });
    };
}

const profileDetailsAction = () => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Profile-details`, {
            Login_user_type: parseInt(getItem('role'), 10) || 9,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || null,
        }, { headers: getItemToken('access_token') || null })
        const ListRes = response.data
        dispatch({ type: "GET_PROFILE_ACTION", payload: ListRes });
    }
    catch (error) {
        dispatch({ type: "GET_PROFILE_ACTION_FAILED", payload: error });
    };
}

const ageCategoryAction = (accessHeader) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_Baseurl}/admin/age-category/index`,
            { headers: accessHeader }
        );
        const ListRes = response.data.Home_page;
        dispatch({ type: "GET_AGECATEGORY_ACTION", payload: ListRes });
    } catch (error) {
        dispatch({ type: "GET_AGECATEGORY_ACTION_FAILED", payload: error });
    }
}

const viewAllPageAction = (userId, userType, deviceType, accessHeader, requestApi) => async (dispatch) => {
    try {
        const response = await axios.post(`${requestApi}`, {
            Login_user_type: userType,
            Login_device_type: deviceType,
            Login_user_id: userId,
        }, { headers: accessHeader })
        const ListRes = response.data
        dispatch({ type: "GET_VIEWALLPAGE_ACTION", payload: ListRes });
    }
    catch (error) {
        dispatch({ type: "GET_VIEWALLPAGE_FAILED", payload: error });
    };
}
const languagePageListAction = (userId, userType, deviceType, accessHeader, requestApi) => async (dispatch) => {
    try {
        const response = await axios.post(`${requestApi}`, {
            Login_user_type: userType,
            Login_device_type: deviceType,
            Login_user_id: userId,
        }, { headers: accessHeader })
        const ListRes = response.data.data
        dispatch({ type: "GET_LANGUAGE_PAGE_LIST_ACTION", payload: ListRes });
        return ListRes
    }
    catch (error) {
        dispatch({ type: "GET_LANGUAGE_PAGE_LIST_FAILED", payload: error });
    };
}
const liveCategoriesPageListAction = (userId, userType, deviceType, accessHeader, requestApi) => async (dispatch) => {
    try {
        const response = await axios.post(`${requestApi}`, {
            Login_user_type: userType,
            Login_device_type: deviceType,
            Login_user_id: userId,
        }, { headers: accessHeader })
        const ListRes = response.data.data
        dispatch({ type: "GET_LIVECAT_PAGE_LIST_ACTION", payload: ListRes });
        return ListRes
    }
    catch (error) {
        dispatch({ type: "GET_LIVECAT_PAGE_LIST_FAILED", payload: error });
    };
}
const videoCategoriesPageListAction = (userId, userType, deviceType, accessHeader, requestApi) => async (dispatch) => {
    try {
        const response = await axios.post(`${requestApi}`, {
            Login_user_type: userType,
            Login_device_type: deviceType,
            Login_user_id: userId,
        }, { headers: accessHeader })
        const ListRes = response.data.data
        dispatch({ type: "GET_VIDEOCAT_PAGE_LIST_ACTION", payload: ListRes });
        return ListRes
    }
    catch (error) {
        dispatch({ type: "GET_VIDEOCAT_PAGE_LIST_FAILED", payload: error });
    };
}

const seriesCategoriesPageListAction = (userId, userType, deviceType, accessHeader, requestApi) => async (dispatch) => {
    try {
        const response = await axios.post(`${requestApi}`, {
            Login_user_type: userType,
            Login_device_type: deviceType,
            Login_user_id: userId,
        }, { headers: accessHeader })
        const ListRes = response.data.data
        dispatch({ type: "GET_SERIESCAT_PAGE_LIST_ACTION", payload: ListRes });
        return ListRes
    }
    catch (error) {
        dispatch({ type: "GET_SERIESCAT_PAGE_LIST_FAILED", payload: error });
    };
}
const audioCategoriesPageListAction = (userId, userType, deviceType, accessHeader, requestApi) => async (dispatch) => {
    try {
        const response = await axios.post(`${requestApi}`, {
            Login_user_type: userType,
            Login_device_type: deviceType,
            Login_user_id: userId,
        }, { headers: accessHeader })
        const ListRes = response.data.data
        dispatch({ type: "GET_AUDIOCAT_PAGE_LIST_ACTION", payload: ListRes });
        return ListRes
    }
    catch (error) {
        dispatch({ type: "GET_AUDIOCAT_PAGE_LIST_FAILED", payload: error });
    };
}
const viewAllPageIdAction = (userId, userType, deviceType, ID, accessHeader, requestApi) => async (dispatch) => {
    try {
        const response = await axios.post(`${requestApi}`, {
            Login_user_type: userType,
            Login_device_type: deviceType,
            Login_user_id: userId,
            language_id: ID
        }, { headers: accessHeader })
        const ListRes = response.data
        dispatch({ type: "GET_VIEWALLPAGEID_ACTION", payload: ListRes });

    }
    catch (error) {
        dispatch({ type: "GET_VIEWALLPAGEID_FAILED", payload: error });
    };
}
const viewAllPageCategoryIdAction = (userId, userType, deviceType, slug, accessHeader, requestApi) => async (dispatch) => {
    try {
        const response = await axios.post(`${requestApi}`, {
            Login_user_type: userType,
            Login_device_type: deviceType,
            Login_user_id: userId,
            category_slug: slug
        }, { headers: accessHeader })
        const ListRes = response.data
        dispatch({ type: "GET_VIEWALLPAGECATID_ACTION", payload: ListRes });

    }
    catch (error) {
        dispatch({ type: "GET_VIEWALLPAGECATID_FAILED", payload: error });
    };
}
const getwatchLaterListAction = (userId, userType, deviceType, accessHeader) => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Watchlater-List`, {
            Login_user_type: userType,
            Login_device_type: deviceType,
            Login_user_id: userId,
        }, { headers: accessHeader })
        const ListRes = response.data
        dispatch({ type: "GET_WATCHLATERLIST_ACTION", payload: ListRes });

    }
    catch (error) {
        dispatch({ type: "GET_WATCHLATERLIST_FAILED", payload: error });
    };
}
const getWishListAction = (userId, userType, deviceType, accessHeader) => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Wishlist-List`, {
            Login_user_type: parseInt(getItem('role'), 10) || 9,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || null,
        }, { headers: getItemToken('access_token') || null })
        const ListRes = response.data
        dispatch({ type: "GET_WISHLIST_ACTION", payload: ListRes });

    }
    catch (error) {
        dispatch({ type: "GET_WISHLIST_FAILED", payload: error });
    };
}
const getMusicSettingsAction = (accessHeader) => async (dispatch) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Music-theme-setting`, { headers: accessHeader })
        const ListRes = response?.data?.Music_Setting
        dispatch({ type: "GET_MUSIC_ACTION", payload: ListRes });
    }
    catch (error) {
        dispatch({ type: "GET_MUSIC_FAILED", payload: error });
    };
}

const userChangeLanguage = (code, navigate) => async (dispatch) => {
    try {
        await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Choose-Language`, {
            Language_Code: code,
            Login_user_type: parseInt(getItem('role'), 10) || 9,
            Login_user_id: parseInt(getItem('user_id'), 10) || null,
            Login_device_type: "web",
        });
        dispatch(getUserTranslateLanguageAction(navigate))
    } catch (error) {
    }
}
const userTranslateLanguage = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/User-Translate-Language`, {
            Login_user_type: parseInt(getItem('role'), 10) || 9,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || '',
        }, { headers: getItemToken('access_token') || null }
        )
        const languageResponse = response?.data;

        return languageResponse;
    }
    catch (error) {

    };
}

let isToastShown = false;

const LogOutApi = (navigate) => async (dispatch) => {
    try {
        const logoutResponse = await axios.get(`${process.env.REACT_APP_Baseurl}/users/logout`, {
            headers: getItemToken('access_token'),
        });

        if (logoutResponse?.data?.status) {
            if (!isToastShown) { // Show toast only if not shown already
                toast.success(logoutResponse.data.message);
                isToastShown = true; // Set flag to prevent further toasts
            }

            await dispatch(getUserTranslateLanguageAction(navigate));
            dispatch({ type: "OPEN_PLAYLIST", payload: false });
            dispatch({ type: "GET_AUDIO_SUCCESS", payload: null });
            dispatch({ type: "GET_CURRENT_SUCCESS", payload: null });
            dispatch({ type: "GET_CLOSE_SUCCESS", isClose: false });

            // Clear localStorage after successfully dispatching
            localStorage.clear();

            navigate('/'); // Ensure redirection after logout if needed
        } else {
            toast.error(logoutResponse.data.message);
        }
    } catch (logoutError) {
        console.error('Logout error:', logoutError);
    }
};


const useAutoLogout = (expires_in, navigate) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const loginTime = localStorage.getItem('loginTime');
        let remainingTime = expires_in * 1000; // Convert expires_in to milliseconds

        if (loginTime) {
            const elapsedTime = Date.now() - new Date(loginTime).getTime();
            remainingTime = remainingTime - elapsedTime; // Calculate remaining time based on elapsed time

            if (remainingTime <= 0) {
                // If the token has already expired, log out immediately
                dispatch(LogOutApi(navigate));
            } else {
                // Set a timeout to log the user out when the token expires
                const expirationTimeout = setTimeout(() => {
                    dispatch(LogOutApi(navigate));
                }, remainingTime);

                // Clear the timeout if the component unmounts
                return () => clearTimeout(expirationTimeout);
            }
        } else {
            // Store the current time as the login time if not already stored
            localStorage.setItem('loginTime', new Date().toISOString());
        }
    }, [expires_in, dispatch, navigate]);
};

const getFieldEnable = () => async (dispatch) => {
    await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/SignupMenu-Settings`).then((res) => {
        dispatch({ type: "SIGNUP-SETTINGS", payload: res?.data?.signup_menu });
    }).catch(() => {
        console.error("Error")
    })
}


const clearViewAllList = () => ({ type: "CLEAR_VIEWALLLIST" });
export { getFieldEnable, showHomePageAction, movieHomePageAction, LogOutApi, useAutoLogout, userTranslateLanguage, userChangeLanguage, footerAction, homeBannerAction, viewAllAction, settingAction, filterAction, siteThemeAction, displayTranslateLanguageAction, getUserTranslateLanguageAction, videoDetailsAction, seriesDetailsAction, seasonDetailsAction, liveDetailsAction, clearViewAllList, allHomePageAction, channelDetailsAction, contentDetailsAction, allThumbnailAction, headerIndexAction, profileDetailsAction, ageCategoryAction, viewAllPageAction, languagePageListAction, viewAllPageIdAction, liveCategoriesPageListAction, viewAllPageCategoryIdAction, videoCategoriesPageListAction, seriesCategoriesPageListAction, audioCategoriesPageListAction, episodeDetailsAction, getwatchLaterListAction, getWishListAction, getMusicSettingsAction, artistsDetailsAction }