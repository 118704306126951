import React, { useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Loader from "../../components/Images/loaderget.gif";
import { videoDetailsAction } from "../../Redux/Actions/apiActions";
import { useNavigate } from "react-router-dom";
import VideosJs from "./VideosJs";
import { useTranslation } from "react-i18next";
import AudioVideoplayer from "./AudioVideoplayer";

const VideoPlayer = ({ videoDetailsData, isLoading, error, settings, slug, getWishListData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const playerType = videoDetailsData?.type;
  const embedSource = videoDetailsData?.embed_code;
  const access_token = localStorage.getItem("access_token");
  const headers = { "Content-Type": "application/json", Authorization: "Bearer " + access_token, Accept: "application/json" };
  const videoJsOptions = {
    controlBar: {
      audioTrackButton: true,
      subtitlesButton: false,
      remainingTimeDisplay: true,
      // volumePanel: false,
      fullscreenToggle: false,
      volumePanel: { inline: false, vertical: true },
      pictureInPictureToggle: true
    },
    bigPlayButton: false,
    autoplay: true,
    controls: true,
    responsive: true,
    breakpoints: [{ tiny: 300, xsmall: 400, small: 500, medium: 600, large: 700, xlarge: 800, huge: 900 }],
    poster: videoDetailsData?.Player_thumbnail,
    textTrackSettings: false,
    fluid: true,
    sources: [{
      src: videoDetailsData?.videos_url,
      type: videoDetailsData?.video_player_type
    }],
    playbackRates: [0.5, 1, 1.25, 1.5, 1.75, 2],
    html5: {
      nativeTextTracks: false,
      hls: { enableLowInitialPlaylist: true, smoothQualityChange: true }
    }
  };

  useEffect(() => {
    if (settings && Object.keys(settings).length > 0) {
      !user_role && settings.access_free == 1
        ? dispatch(videoDetailsAction(null, 9, "web", slug, headers))
        : user_role &&
        dispatch(
          videoDetailsAction(user_id, user_role, "web", slug, headers)
        );
    }
  }, [settings, user_role]);
  const playerRef = React.useRef(null);
  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.on("waiting", () => {
      // videojs.log("player is waiting");
    });
    player.on("dispose", () => {
      // videojs.log("player will dispose");
    });
  };
  //console.log(videoDetailsData,"video")
  var imaOptions = {
    forceNonLinearFullSlot: true,
    showControlsForAds: true,
    timeout: 5000,
    prerollTimeout: 10000,
    debug: true,
    adTagUrl: `https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/vmap_ad_samples&sz=640x480&cust_params=sample_ar%3Dpremidpostpodbumper&ciu_szs=300x250&gdfp_req=1&ad_rule=1&output=vmap&unviewed_position_start=1&env=vp&impl=s&cmsid=496&vid=short_onecue&correlator=`
  };
  const audioTracks = [
    {
      id: '1',
      kind: 'alternative',
      label: 'English',
      language: 'en',
      enabled: true,
      src: 'https://api.flicknexs.com/public/uploads/audios/Audios-1723808881.mp3', // Path to the English audio track
    },
    {
      id: '2',
      kind: 'alternative',
      label: 'Spanish',
      language: 'es',
      enabled: false,
      src: 'https://api.flicknexs.com/public/uploads/audios/Audios-1723808881.mp3', // Path to the Spanish audio track
    },
    {
      id: '3',
      kind: 'alternative',
      label: 'French',
      language: 'fr',
      enabled: false,
      src: 'https://api.flicknexs.com/public/uploads/audios/Audios-1723808881.mp3', // Path to the French audio track
    }];

  const wishListCheck = getWishListData?.videos?.some(item => item.id === videoDetailsData.id);
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center paddingTop">
          <img src={Loader} width={50} height={50} />
        </div>
      ) : playerType == "embed_url|url" ? (
        <iframe src={embedSource} allowfullscreen width="1360" height="628"></iframe>
      ) : videoDetailsData?.videos_url && videoDetailsData?.video_player_type
        ? <>
          <VideosJs
            options={videoJsOptions}
            onReady={handlePlayerReady}
            videoDetailsData={videoDetailsData}
            imaOptions={imaOptions}
            audioTracks={audioTracks}
            settings={settings}
            wishListCheckValue={wishListCheck} />

          {/* <AudioVideoplayer
            options={videoJsOptions}
            onReady={handlePlayerReady}
            videoDetailsData={videoDetailsData}
            imaOptions={imaOptions}
            audioTracks={audioTracks}
            settings={settings} /> */}
        </>
        : <div className='d-flex justify-content-center align-items-center paddingTop'>
          <h3>{t("Invalid Video src and type")}</h3>
        </div>
      }
    </>
  );
};
const mapStateToProps = (state) => ({
  videoDetailsData: state.get_videoDetails_Reducer.videoDetailsData,
  isLoading: state.get_videoDetails_Reducer.isLoading,
  settings: state.get_setting_Reducer.settings,
  getWishListData: state?.get_wishList_Reducer?.getWishListData
});
export default connect(mapStateToProps)(VideoPlayer);
