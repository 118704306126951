import React, { useEffect, useRef, useState } from "react";
import videojs from 'video.js';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "video.js/dist/video-js.css";
import "./videoPlayer.css";
import { BiArrowBack } from "react-icons/bi";
import { FaPlay, FaPause } from 'react-icons/fa';
import { GrForwardTen, GrBackTen } from "react-icons/gr";
import { VscDebugRestart } from "react-icons/vsc";
import AdInformationCard from "./AdInformationCard";
import EndCard from "./EndCard";
import { useTranslation } from "react-i18next";
import { BsArrowsFullscreen, BsFullscreenExit } from "react-icons/bs";
import NextVideosSlider from "./NextVideosSlider";
import ToggleButton from "./ToggleButton";
import RelatedVideosSwiper from "./ReletedVideosSwiper";
import { MdOutlineQueuePlayNext } from "react-icons/md";
import axios from "axios";
import "videojs-contrib-ads";
import "videojs-ima";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { episodeDetailsAction } from "../../Redux/Actions/apiActions";
import { wishListAction } from "../../Pages/CommonMethods/CommonMethods";

export const SeriesJs = ({ episodeDetailsData, options, onReady, settings, imaOptions, wishListCheckValue }) => {

  const [currentEpisode, setCurrentEpisode] = useState(episodeDetailsData);
  const [episodeCompleted, setEpisodeCompleted] = useState(false);
  const getLastViewedVideo = episodeDetailsData?.Episode_details?.continue_watching_exists?.current_time;
  const { t } = useTranslation();
  const { lang, series_slug } = useParams();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const navigate = useNavigate();
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(0);
  const [watchPercentage, setWatchPercentage] = useState(0);
  const [showNextButton, setShowNextButton] = useState(false);
  const [buttonFillPercentage, setButtonFillPercentage] = useState(0);
  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json", Authorization: "Bearer " + access_token,
    Accept: "application/json"
  };
  const location = useLocation();
  const [showButton, setShowButton] = useState({
    skipButton: false,
    recapButton: false,
    cardButton: false,
    relatedVideo: false
  });
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const TimeConverter = (data) => {
    const [hours, minutes, seconds] = data?.split(":").map(Number);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  }
  const handleRecap = () => {
    const currentTime = playerRef.current.currentTime();
    const backTime = currentTime - TimeConverter(episodeDetailsData?.Episode_details?.recap_start_session);
    playerRef.current.currentTime(backTime);
    setShowButton((prevState) => ({ ...prevState, recapButton: false, }));

  };
  const handleSkip = () => {
    const currentTime = playerRef.current.currentTime();
    const backTime = currentTime + TimeConverter(episodeDetailsData?.Episode_details?.skip_start_session);
    playerRef.current.currentTime(backTime);
    setShowButton((prevState) => ({ ...prevState, skipButton: false, }));
  }
  const handleFullscreen = () => {
    const player = playerRef.current;
    if (player) {
      if (player.isFullscreen()) {
        player.exitFullscreen();
      } else {
        player.requestFullscreen();
      }
    }
  };
  const dispatch = useDispatch();

  const episodeApi = async (episodeSlug) => {
    //console.log(seriesSlug,episodeSlug)
    if (settings && Object.keys(settings).length > 0) {
      try {
        let actionResult;
        if (!user_role && settings.access_free == 1) {
          actionResult = await dispatch(episodeDetailsAction(null, 9, "web", series_slug, episodeSlug, headers));
        } else if (user_role) {
          actionResult = await dispatch(episodeDetailsAction(user_id, user_role, "web", series_slug, episodeSlug, headers));
        }
        //console.log(actionResult)
        return { status: true, data: actionResult }; // Return the action result as API response
      } catch (error) {
        console.error('Error fetching episode data:', error);
        return { status: false, error: error.message }; // Return error information if there's an error
      }
    } else {
      return { status: false, error: 'Invalid settings' }; // Return error if settings are invalid
    }
  };


  // const handleVideoEnded = async () => {
  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Series-details`, {
  //       Login_user_type: user_role,
  //       Login_device_type: 'web',
  //       Login_user_id: user_id,
  //       series_slug: series_slug,
  //     })
  //     const seriesDetailsData = response?.data?.series_details;
  //     console.log(seriesDetailsData)
  //     if (seriesDetailsData && seriesDetailsData?.series_season) {
  //       const firstSeason = seriesDetailsData?.series_season[0];
  //       if (firstSeason.episodes && firstSeason.episodes.length > 0) {
  //         const episodeSlug = firstSeason.episodes[1].slug;
  //         console.log(episodeSlug)
  //         try {
  //           const response = await episodeApi(episodeSlug);
  //           if (response?.status && response?.data?.users_episode_visibility?.users_episode_visibility_status) {
  //             navigate(`/${lang}/series/${series_slug}/${episodeSlug}`);
  //           } else {
  //             navigate(`/${lang}/episode/${series_slug}/${episodeSlug}`);
  //           }
  //         } catch (error) {
  //           console.error('Error calling episodeApi:', error);
  //         }
  //       } else {
  //         console.error('No episodes found in the first season.');
  //       }
  //     } else {
  //       console.error('No series season data found.');
  //     }

  //   }
  //   catch (error) {
  //     console.log(error)
  //   };};
  // // Handle video ended event
  // const handleVideoEnded = async () => {
  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Series-details`, {
  //       Login_user_type: user_role, // replace with actual user role
  //       Login_device_type: 'web',
  //       Login_user_id: user_id, // replace with actual user ID
  //       series_slug: series_slug,
  //     });
  //     const seriesDetailsData = response?.data?.series_details;
  //     if (seriesDetailsData && seriesDetailsData?.series_season) {
  //       const firstSeason = seriesDetailsData?.series_season[0];
  //       if (firstSeason.episodes && firstSeason.episodes.length > 0) {
  //         const nextEpisodeIndex = firstSeason.episodes.findIndex(episode => episode.slug === currentEpisode?.Episode_details?.slug) + 1;
  //         if (nextEpisodeIndex < firstSeason.episodes.length) {
  //           const nextEpisodeSlug = firstSeason.episodes[nextEpisodeIndex].slug;
  //           try {
  //             const response = await episodeApi(nextEpisodeSlug);  // Assuming episodeApi is a function to fetch episode details
  //             if (response?.status && response?.data?.users_episode_visibility?.users_episode_visibility_status) {
  //               setCurrentEpisode(response.data);  // Update current episode details
  //               console.log(response?.data)
  //               // playerRef.current.src({ type: 'video/mp4', 
  //               //   src: response.data.Episode_details.video_url });
  //               playerRef.current.play();
  //               navigate(`/${lang}/episode/${series_slug}/${nextEpisodeSlug}`);
  //             } else {
  //               navigate(`/${lang}/series/${series_slug}/${nextEpisodeSlug}`);
  //             }
  //           } catch (error) {
  //             console.error('Error calling episodeApi:', error);
  //           }
  //         } else {
  //           console.error('No more episodes in the season.');
  //         }
  //       } else {
  //         console.error('No episodes found in the first season.');
  //       }
  //     } else {
  //       console.error('No series season data found.');
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };


  useEffect(() => {
    if (videoRef.current && episodeDetailsData?.Episode_details) {
      //   const videoElement = videoRef.current.querySelector('video');
      //   if (!videoElement) return;
      // const player = videojs(videoRef.current, options, () => {
      const videoElement = videoRef.current;
      if (!videoElement || !episodeDetailsData?.Episode_details) return;

      const player = videojs(videoElement, options, () => {
        playerRef.current = player;

        // Add subtitles
        episodeDetailsData?.Episode_details?.Subtitle?.forEach((subtitle) => {
          player.addRemoteTextTrack({
            kind: 'captions',
            label: subtitle?.subtitle_language,
            srclang: subtitle?.shortcode,
            src: subtitle?.subtitle_url,
          });
        });

        player.loadingSpinner.removeClass('vjs-loading-spinner');
        player.loadingSpinner.addClass('vjs-loading-spinner-myvideo');



        const toggleButtons = () => {
          const currentTime = player.currentTime();
          const startShowTimeSkip = TimeConverter(
            episodeDetailsData?.Episode_details?.skip_start_time
          );
          const endShowTimeSkip = TimeConverter(
            episodeDetailsData?.Episode_details?.skip_end_time
          );
          const startShowTimeRecap = TimeConverter(
            episodeDetailsData?.Episode_details?.recap_start_time
          );
          const endShowTimeRecap = TimeConverter(
            episodeDetailsData?.Episode_details?.recap_end_time
          );
          const startShowTimeCard = TimeConverter(
            episodeDetailsData?.Episode_details?.url_linktym
          );
          const endShowTimeCard = TimeConverter(
            episodeDetailsData?.Episode_details?.urlEnd_linksec
          );

          setShowButton((prevState) => ({
            ...prevState,
            skipButton:
              currentTime >= startShowTimeSkip && currentTime <= endShowTimeSkip,
            recapButton:
              currentTime >= startShowTimeRecap &&
              currentTime <= endShowTimeRecap,
            cardButton:
              currentTime >= startShowTimeCard && currentTime <= endShowTimeCard,
            relatedVideo: false,
          }));
        };

        onReady && onReady(player);

        // Event listeners
        player.on('fullscreenchange', () => setIsFullscreen(player.isFullscreen()));
        player.on('play', () => setIsPlaying(true));
        player.on('pause', () => setIsPlaying(false));
        player.on('userinactive', () => {
          const playPauseButton = document.querySelector('.custom-play-pause-button');
          if (playPauseButton) {
            playPauseButton.style.display = 'none';
          }
          const skipForwardButton = document.querySelector('.custom-skip-forward-button');
          const skipBackwardButton = document.querySelector('.custom-skip-backward-button');
          if (skipForwardButton && skipBackwardButton) {
            skipForwardButton.style.display = 'none';
            skipBackwardButton.style.display = 'none';
          }
        });
        player.on('useractive', () => {
          const playPauseButton = document.querySelector('.custom-play-pause-button');
          if (playPauseButton) {
            playPauseButton.style.display = 'block';
          }
          const skipForwardButton = document.querySelector('.custom-skip-forward-button');
          const skipBackwardButton = document.querySelector('.custom-skip-backward-button');
          if (skipForwardButton && skipBackwardButton) {
            skipForwardButton.style.display = 'block';
            skipBackwardButton.style.display = 'block';
          }
        });
        player.on('timeupdate', toggleButtons);
        player.on('ended', () => {
          setShowButton((prevState) => ({ ...prevState, relatedVideo: true }));
          if (!wishListCheckValue) {
            wishListAction(null, null, null, episodeDetailsData?.id, 4);
          }
        });
        // player.on('ended', handleVideoEnded);
        player.on('timeupdate', () => {
          setCurrentTime(player.currentTime());
          setPlayedSeconds(player.currentTime());

          const current = player.currentTime();
          if (duration - current <= 4) {
            setShowNextButton(true);
            setButtonFillPercentage(((- (duration - current)) / 5) * 100);
          } else {
            setShowNextButton(false);
          }
        });
        player.on('loadedmetadata', () => {
          setDuration(player.duration());

          // Seek to the last viewed position
          if (getLastViewedVideo) {
            player.currentTime(getLastViewedVideo);
          }

          // Update the progress control bar
          const updateProgressControl = () => {
            const duration = player.duration();
            const watchedPercentage = (getLastViewedVideo / duration) * 100;

            const playProgress = player.controlBar.progressControl.el().querySelector('.vjs-play-progress');
            const sliderBar = player.controlBar.progressControl.el().querySelector('.vjs-slider-bar');

            if (playProgress && sliderBar) {
              playProgress.style.width = `${watchedPercentage}%`;
              sliderBar.style.width = `${watchedPercentage}%`;
            }
          };
          updateProgressControl();
        });
        // Disable right-click context menu
        const handleContextMenu = (e) => e.preventDefault();
        videoElement.addEventListener('contextmenu', handleContextMenu);

        // Prevent dragging
        const handleDragStart = (e) => e.preventDefault();
        videoElement.addEventListener('dragstart', handleDragStart);

        // Disable video controls
        videoElement.controls = false;

        return () => {
          videoElement.removeEventListener('contextmenu', handleContextMenu);
          videoElement.removeEventListener('dragstart', handleDragStart);
          player.dispose();
        };


      });

    } else {
      return null;
    }
  }, [currentEpisode, episodeDetailsData, duration]);

  // useEffect(() => {
  //   if (videoRef.current && episodeDetailsData?.Episode_details) {
  //     const videoElement = videoRef.current.querySelector('video');
  //     if (!videoElement) return;

  //     const player = videojs(videoElement, options, () => {
  //       playerRef.current = player;

  //       // Add subtitles
  //       episodeDetailsData?.Episode_details?.Subtitle?.forEach(subtitle => {
  //         player.addRemoteTextTrack({
  //           kind: 'captions',
  //           label: subtitle?.subtitle_language,
  //           srclang: subtitle?.shortcode,
  //           src: subtitle?.subtitle_url
  //         });
  //       });

  //       player.loadingSpinner.removeClass("vjs-loading-spinner");
  //       player.loadingSpinner.addClass("vjs-loading-spinner-myvideo");

  //       const toggleButtons = () => {
  //         const currentTime = player.currentTime();
  //         const startShowTimeSkip = TimeConverter(episodeDetailsData?.Episode_details?.skip_start_time);
  //         const endShowTimeSkip = TimeConverter(episodeDetailsData?.Episode_details?.skip_end_time);
  //         const startShowTimeRecap = TimeConverter(episodeDetailsData?.Episode_details?.recap_start_time);
  //         const endShowTimeRecap = TimeConverter(episodeDetailsData?.Episode_details?.recap_end_time);
  //         const startShowTimeCard = TimeConverter(episodeDetailsData?.Episode_details?.url_linktym);
  //         const endShowTimeCard = TimeConverter(episodeDetailsData?.Episode_details?.urlEnd_linksec);

  //         setShowButton((prevState) => ({
  //           ...prevState,
  //           skipButton: currentTime >= startShowTimeSkip && currentTime <= endShowTimeSkip,
  //           recapButton: currentTime >= startShowTimeRecap && currentTime <= endShowTimeRecap,
  //           cardButton: currentTime >= startShowTimeCard && currentTime <= endShowTimeCard,
  //           relatedVideo: false,
  //         }));
  //       };

  //       onReady && onReady(player);

  //       // Event listeners
  //       player.on('fullscreenchange', () => setIsFullscreen(player.isFullscreen()));
  //       player.on('play', () => setIsPlaying(true));
  //       player.on('pause', () => setIsPlaying(false));
  //       player.on('userinactive', () => {
  //         const playPauseButton = document.querySelector('.custom-play-pause-button');
  //         if (playPauseButton) {
  //           playPauseButton.style.display = 'none';
  //         }
  //         const skipForwardButton = document.querySelector('.custom-skip-forward-button');
  //         const skipBackwardButton = document.querySelector('.custom-skip-backward-button');
  //         if (skipForwardButton && skipBackwardButton) {
  //           skipForwardButton.style.display = 'none';
  //           skipBackwardButton.style.display = 'none';
  //         }
  //       });
  //       player.on('useractive', () => {
  //         const playPauseButton = document.querySelector('.custom-play-pause-button');
  //         if (playPauseButton) {
  //           playPauseButton.style.display = 'block';
  //         }
  //         const skipForwardButton = document.querySelector('.custom-skip-forward-button');
  //         const skipBackwardButton = document.querySelector('.custom-skip-backward-button');
  //         if (skipForwardButton && skipBackwardButton) {
  //           skipForwardButton.style.display = 'block';
  //           skipBackwardButton.style.display = 'block';
  //         }
  //       });
  //       player.on('timeupdate', toggleButtons);
  //       player.on('ended', () => { setShowButton((prevState) => ({ ...prevState, relatedVideo: true })); });
  //       player.on('ended', handleVideoEnded);
  //       player.on('timeupdate', () => {
  //         setCurrentTime(player.currentTime());
  //         setPlayedSeconds(player.currentTime());

  //         const current = player.currentTime();
  //         if (duration - current <= 4) {
  //           setShowNextButton(true);
  //           setButtonFillPercentage(((- (duration - current)) / 5) * 100);
  //         } else {
  //           setShowNextButton(false);
  //         }
  //       });
  //       player.on('loadedmetadata', () => {
  //         setDuration(player.duration());

  //         // Seek to the last viewed position
  //         if (getLastViewedVideo) {
  //           player.currentTime(getLastViewedVideo);
  //         }

  //         // Update the progress control bar
  //         const updateProgressControl = () => {
  //           const duration = player.duration();
  //           const watchedPercentage = (getLastViewedVideo / duration) * 100;

  //           const playProgress = player.controlBar.progressControl.el().querySelector('.vjs-play-progress');
  //           const sliderBar = player.controlBar.progressControl.el().querySelector('.vjs-slider-bar');

  //           if (playProgress && sliderBar) {
  //             playProgress.style.width = `${watchedPercentage}%`;
  //             sliderBar.style.width = `${watchedPercentage}%`;
  //           }
  //         };
  //         updateProgressControl();
  //       });

  //       // Disable right-click context menu
  //       const handleContextMenu = (e) => {
  //         e.preventDefault();
  //       };
  //       videoElement.addEventListener('contextmenu', handleContextMenu);

  //       // Prevent dragging
  //       videoElement.addEventListener('dragstart', (e) => {
  //         e.preventDefault();
  //       });

  //       // Disable video controls
  //       videoElement.controls = false;

  //       // Cleanup function
  //       return () => {
  //         videoElement.removeEventListener('contextmenu', handleContextMenu);
  //         videoElement.removeEventListener('dragstart', (e) => e.preventDefault());
  //         player.dispose();
  //       };
  //     });
  //   } else {
  //     return null;
  //   }
  // }, [currentEpisode, episodeDetailsData, duration]);

  // useEffect(() => {
  //   if (videoRef.current && episodeDetailsData?.Episode_details) {
  //     const player = videojs(videoRef.current, options, () => {
  //       playerRef.current = player;
  //       episodeDetailsData?.Episode_details?.Subtitle?.forEach(subtitle => {
  //         player.addRemoteTextTrack({
  //           kind: 'captions',
  //           label: subtitle?.subtitle_language,
  //           srclang: subtitle?.shortcode,
  //           src: subtitle?.subtitle_url
  //         });
  //       });
  //       player.loadingSpinner.removeClass("vjs-loading-spinner");
  //       player.loadingSpinner.addClass("vjs-loading-spinner-myvideo");
  //       const toggleButtons = () => {
  //         const currentTime = player.currentTime();
  //         const startShowTimeSkip = TimeConverter(episodeDetailsData?.Episode_details?.skip_start_time);
  //         const endShowTimeSkip = TimeConverter(episodeDetailsData?.Episode_details?.skip_end_time);
  //         const startShowTimeRecap = TimeConverter(episodeDetailsData?.Episode_details?.recap_start_time);
  //         const endShowTimeRecap = TimeConverter(episodeDetailsData?.Episode_details?.recap_end_time);
  //         const startShowTimeCard = TimeConverter(episodeDetailsData?.Episode_details?.url_linktym);
  //         const endShowTimeCard = TimeConverter(episodeDetailsData?.Episode_details?.urlEnd_linksec);

  //         setShowButton((prevState) => ({
  //           ...prevState,
  //           skipButton: currentTime >= startShowTimeSkip && currentTime <= endShowTimeSkip,
  //           recapButton: currentTime >= startShowTimeRecap && currentTime <= endShowTimeRecap,
  //           cardButton: currentTime >= startShowTimeCard && currentTime <= endShowTimeCard,
  //           relatedVideo: false,
  //         }));
  //       };

  //       onReady && onReady(player);
  //       player.on('fullscreenchange', () => {
  //         setIsFullscreen(player.isFullscreen());
  //       });
  //       player.on('play', () => setIsPlaying(true));
  //       player.on('pause', () => setIsPlaying(false));
  //       player.on('userinactive', () => {
  //         const playPauseButton = document.querySelector('.custom-play-pause-button');
  //         if (playPauseButton) {
  //           playPauseButton.style.display = 'none';
  //         }
  //       });
  //       player.on('useractive', () => {
  //         const playPauseButton = document.querySelector('.custom-play-pause-button');
  //         if (playPauseButton) {
  //           playPauseButton.style.display = 'block';
  //         }
  //       });
  //       player.on('userinactive', () => {
  //         const skipForwardButton = document.querySelector('.custom-skip-forward-button');
  //         const skipBackwardButton = document.querySelector('.custom-skip-backward-button');
  //         if (skipForwardButton && skipBackwardButton) {
  //           skipForwardButton.style.display = 'none';
  //           skipBackwardButton.style.display = 'none';
  //         }
  //       });
  //       player.on('useractive', () => {
  //         const skipForwardButton = document.querySelector('.custom-skip-forward-button');
  //         const skipBackwardButton = document.querySelector('.custom-skip-backward-button');
  //         if (skipForwardButton && skipBackwardButton) {
  //           skipForwardButton.style.display = 'block';
  //           skipBackwardButton.style.display = 'block';
  //         }
  //       });
  //       player.on('timeupdate', toggleButtons);
  //       player.on('ended', () => { setShowButton((prevState) => ({ ...prevState, relatedVideo: true })); })
  //       player.on('ended', handleVideoEnded);
  //       player.on('timeupdate', () => {
  //         setCurrentTime(player.currentTime());
  //         setPlayedSeconds(player.currentTime());
  //         const current = player.currentTime();
  //         // if (duration - currentTime <= 5) {
  //         //   setShowNextButton(true);
  //         //   setButtonFillPercentage(((duration - currentTime) / 5) * 100);
  //         // } else {
  //         //   setShowNextButton(false);
  //         // }
  //         if (duration - current <= 4) {
  //           setShowNextButton(true);
  //           setButtonFillPercentage(((- (duration - current)) / 5) * 100);
  //         } else {
  //           setShowNextButton(false);
  //         }
  //       });
  //       player.on('loadedmetadata', () => {
  //         setDuration(player.duration());

  //         // Seek to the last viewed position
  //         if (getLastViewedVideo) {
  //           player.currentTime(getLastViewedVideo);
  //         }

  //         // Update the progress control bar
  //         const updateProgressControl = () => {
  //           const duration = player.duration();
  //           const watchedPercentage = (getLastViewedVideo / duration) * 100;

  //           // Access and update the progress control
  //           const playProgress = player.controlBar.progressControl.el().querySelector('.vjs-play-progress');
  //           const sliderBar = player.controlBar.progressControl.el().querySelector('.vjs-slider-bar');

  //           if (playProgress && sliderBar) {
  //             playProgress.style.width = `${watchedPercentage}%`;
  //             sliderBar.style.width = `${watchedPercentage}%`;
  //           }
  //         };
  //         updateProgressControl();
  //       });

  //     });
  //   }
  //   else {
  //     return null
  //   }
  // }, [currentEpisode, episodeDetailsData, duration]);


  const newCurrentTime = Math.floor(currentTime);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const [locationData, setLocationData] = useState({
    city: '',
    region: '',
    country: '',
    latitude: '',
    longitude: '',
    ip: '',
  });
  // useEffect(() => {
  //   const fetchLocationPermission = async () => {
  //     if ("geolocation" in navigator) {
  //       navigator.geolocation.getCurrentPosition(
  //         async (position) => {
  //           const { latitude, longitude } = position.coords;
  //           try {
  //             const locationResponse = await axios.get(`${process.env.REACT_APP_API_IP_lac_long}?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`);
  //             const { city, principalSubdivision: region, countryName: country } = locationResponse.data;
  //             setLocationData({ city, region, country, latitude, longitude, ip: '' });

  //           } catch (error) {
  //             console.error('Error fetching location data:', error);
  //             //fetchData(); // fallback to IP-based location fetching
  //           } finally {
  //             setLoading(false);  // Set loading to false after fetching
  //           }
  //         },
  //         (error) => {
  //           console.error('Error getting geolocation:', error);
  //           // fetchData(); // fallback to IP-based location fetching
  //           setLoading(false);  // Set loading to false after fetching
  //         }
  //       );
  //     } else {
  //       //fetchData(); // fallback to IP-based location fetching
  //       setLoading(false);  // Set loading to false after fetching
  //     }
  //   };

  //   fetchLocationPermission();
  // }, []);


  // Calculate watch percentage
  useEffect(() => {
    if (duration > 0) {
      const percentage = (playedSeconds / duration) * 100;
      setWatchPercentage(percentage);
    }
  }, [playedSeconds, duration]);

  const calculatePercentage = watchPercentage.toFixed(2);
  const userRole = user_role ? user_role : '9'; // 9 for guest users
  const SaveLastViewedVideo = async () => {
    try {
      const data = {
        Login_user_type: userRole,
        Login_device_type: 'web',
        Login_user_id: user_id,
        source_type_id: 4,  //For Series
        source_id: episodeDetailsData?.Episode_details?.id,
        source_type: 4,
        current_time: newCurrentTime,
        watch_percentage: `${calculatePercentage}%`,
        skip_time: '0',
        country_name: locationData?.country,
        region_name: locationData?.region,
        city_name: locationData?.city

      }
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/continue-watching-recent-view-store-update`, data);
      setData(response.data);
      console.log(response?.data)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const backButton = () => {
    SaveLastViewedVideo();
    const { access_free } = settings;
    const isGuest = !localStorage.getItem("role");
    const { access, slug } = episodeDetailsData?.Episode_details || {};
    if (window.history.state && window.history.state.idx > 0) {
      window.history.back();
    } else {
      (isGuest && access === "guest" && access_free === 0) ? navigate(`/`) :
        navigate(`/${lang}/shows/${series_slug}`);
    }
  };


  // useEffect(() => {
  //   let myButtonForward, myButtonBackWard;
  //   const player = videojs(videoRef.current, options, () => {
  //     const setupCustomButton = () => {
  //       myButtonForward = player.bigPlayButton.addChild("button", {}, 0);
  //       myButtonForward.addClass("kk");
  //       myButtonForward.el().innerHTML = `<img style="height:35px;width:35px;" src=${forward} alt="Forward">`;
  //       myButtonForward.controlText("Skip Forward 10 Seconds");
  //       myButtonForward.el().onclick = function () {
  //         var currentTime = player.currentTime();
  //         var skipTime = currentTime + 10;
  //         player.currentTime(skipTime);
  //       };

  //       myButtonBackWard = player.bigPlayButton.addChild("button", {}, 0);
  //       myButtonBackWard.addClass("kkk");
  //       myButtonBackWard.el().innerHTML = `<img style="height:35px;width:35px;" src=${backward} alt="Backward">`;
  //       myButtonBackWard.controlText("Skip Back 10 Seconds");
  //       myButtonBackWard.el().onclick = function () {
  //         var currentTime = player.currentTime();
  //         var skipTime = currentTime - 10;
  //         player.currentTime(skipTime);
  //       };
  //     }
  //     setupCustomButton();
  //   })

  // }, [])

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, []);

  useEffect(() => {
    const player = videojs(videoRef.current, options, () => {
      if (player) {
        // player.hlsQualitySelector({ displayCurrentQuality: true });
        player.hlsQualitySelector();
        const timeout = setTimeout(() => setShowButton((prevState) => ({ ...prevState, skipButton: false, })), 10000);
        return () => clearTimeout(timeout);
      }
    })
  }, []);

  // const togglePlayPause = () => {
  //   const player = playerRef.current;
  //   if (player.paused()) {
  //     player.play();
  //   } else {
  //     player.pause();
  //   }
  // };

  const togglePlayPause = () => {
    const player = playerRef.current;
    if (player) {
      if (player.paused()) {
        setTimeout(() => {
          player.play().catch((error) => {
            //alert("Video is loading...please wait")
            //toast.error('The download was unable to complete.');
            console.error('Error playing video:', error);
          });
        }, 100); // 100ms delay
      } else {
        player.pause();
      }
    }
  };
  const skipForward = (duration) => {
    const player = playerRef.current;
    player.currentTime(player.currentTime() + duration);
  };
  const skipBackward = (duration) => {
    const player = playerRef.current;
    player.currentTime(player.currentTime() - duration);
  };



  const [showSlider, setShowSlider] = useState(false);



  const toggleSlider = () => {
    setShowSlider(prevShowSlider => !prevShowSlider);
    setShowButton({
      relatedVideo: false
    })
    console.log('Toggled Slider:', showSlider);
    const swiperContainer = document.querySelector('.swiper-container');
    if (swiperContainer) {
      swiperContainer.style.display = showSlider ? 'block' : 'none';
    }
  };

  useEffect(() => {
    const styleSheet = document.styleSheets[0];
    const keyframes = `
      @keyframes backgroundSlide {
        0% { background-position: 100% 0%; }
        100% { background-position: 0% 0%; }
      }
    `;
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
  }, []);

  const buttonStyle = {
    position: 'absolute',
    top: '68.4%',
    right: '5%',
    background: 'linear-gradient(to right, #fff 50%, #ccc 50%)',
    animation: 'backgroundSlide 5s ease-out ',
    backgroundPosition: '0% 0%',
    backgroundSize: '200% 100%',
    padding: '1rem 2rem',
    fontSize: '1.3rem',
    border: 'none',
    color: 'initial',
  };


  return (
    <>
      <div data-vjs-player className="position-relative" style={{ "overflow": "hidden" }}>
        <div className="d-flex align-items-center position-relative px-3 py-2 gap-3">
          <AiOutlineClose style={{ "fontSize": "35px", "fontWeight": "bold", "fill": "white", "cursor": "pointer" }} onClick={backButton} />
          <h3 className="theme-text-color">{t(episodeDetailsData?.Episode_details?.title)}</h3>
        </div>
        <button className="custom-play-pause-button bg-transparent" onClick={togglePlayPause}>
          {isPlaying ? <FaPause style={{ fontSize: "35px" }} /> : <FaPlay style={{ fontSize: "35px" }} />}
        </button>
        <button className="custom-skip-forward-button bg-transparent" onClick={() => skipForward(10)}>
          <GrForwardTen style={{ fontSize: "38px" }} />
        </button>
        <button className="custom-skip-backward-button bg-transparent" onClick={() => skipBackward(10)}>
          <GrBackTen style={{ fontSize: "38px" }} />
        </button>
        <button className="custom-fullscreen-button bg-transparent" onClick={handleFullscreen}>
          {isFullscreen ? <BsFullscreenExit style={{ fontSize: "32px" }} /> : <BsArrowsFullscreen style={{ fontSize: "32px" }} />}
        </button>
        <div className="d-flex align-items-center position-absolute skipRecapWrapper gap-3">
          {showButton?.skipButton && (<button onClick={handleSkip} className="skipBtn bg-transparent  rounded-2 border border-light px-5 py-3" > <span className="theme-text-color skipText">Skip Intro </span> </button>)}
          {/* {showButton?.recapButton && (<button onClick={handleRecap} className="skipBtn bg-transparent  rounded-2 border border-light px-3 py-2" > <VscDebugRestart className="theme-text-color" style={{ fontSize: "30px" }} /> </button>)} */}
        </div>
        {showButton?.cardButton && <AdInformationCard title={episodeDetailsData?.Episode_details?.title} link={episodeDetailsData?.Episode_details?.url_link} />}

        <video data-vjs-player ref={videoRef} className="vjs-big-play-centered vjs-theme-city my-video-dimensions video-js vjs-play-control customVideoPlayer" />
        {/* {showNextButton && (
          <button className="next-episode-button"
            style={buttonStyle} onClick={handleVideoEnded}>
            Next Episode
          </button>
        )} */}
        {/* {showButton?.relatedVideo && <RelatedVideosSwiper relatedVideo={episodeDetailsData?.Episode_details?.title} onClick={toggleSlider} />} */}

      </div>
    </>
  );
};

export default SeriesJs;