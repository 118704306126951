import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./Store.js";
import { PersistGate } from "redux-persist/integration/react";
import PageLoader from "./components/Others/PageLoader.jsx";
ReactDOM.render(
  <Suspense fallback={<PageLoader />} >
    <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);
reportWebVitals();